import React from "react";
import { useStyles } from "./hero.style";
import ButtonP from "../../buttons/primary";
import ButtonS from "../../buttons/secondary_scrollable";
import { colors } from "../../../styles/colors";
import arrowLeft from "../../../assets/homepage/arrow-left.webp";
import arrowRight from "../../../assets/homepage/arrow-right.webp";

const Hero = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className="wrapper">
        <h1>
          <span>Non-Interest</span> Banking has New Direction
        </h1>
        <div className="cards">
          <div>
            {" "}
            <p>
              Hello, we' re Doyar. The benefits of non-interest banking has been
              limited to a few. So we decided to share it with you. All on your
              phone.
            </p>
            <div className="buttons">
              <ButtonP
                bgColor={colors.doyarPeach}
                to="https://forms.office.com/r/3z6yqBmyfK"
                a="true"
              >
                Join Beta Testing
              </ButtonP>
              <ButtonS
                fontColor={"#fff"}
                borderColor={"#fff"}
                bgColor={colors.doyarDark}
                to="info"
              >
                Explore Doyar
              </ButtonS>
            </div>
          </div>
          <div className="card-right">
            <img
              src={arrowRight}
              alt="arrowright"
              height={"59px"}
              className="right"
            />
            <img
              src={arrowLeft}
              alt="arrowleft"
              height={"182px"}
              className="left"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
