import React from "react";
import { useStyles } from "./style";
import ButtonP from "../../buttons/primary";
import img from "../../../assets/images/join-better-img.webp";

const JoinBetter = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div className="left">
          <h6>
            Your input matters! <br />
            Join Our Beta Testing
          </h6>
          <p>
            Be among the first to experience cutting-edge features and help
            shape the future of our product through valuable feedback to improve
            our user experience.
          </p>
          <ButtonP to="https://forms.office.com/r/3z6yqBmyfK" a="true">
            Join Now
          </ButtonP>
        </div>
        <div className="card">
          <div className="card_img"></div>
        </div>
      </div>
    </div>
  );
};

export default JoinBetter;
