import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import arrowDown from "../../../assets/homepage/arrow-down.svg";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "150px",

    display: "flex",
    flexDirection: "column",
    backgroundImage: `url(${arrowDown})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "245px",
    backgroundPosition: "bottom right",
    backgroundColor: "#fff",
    gap: "103px",
    [theme.breakpoints.down("xl")]: {
      backgroundImage: "none",
    },
    "& .grid": {
      display: "grid",
      gridTemplateColumns: "1.9fr 1fr",
      [theme.breakpoints.down("xl")]: {
        gridTemplateColumns: "1fr",
      },

      "& .slide_show": {
        [theme.breakpoints.down("xl")]: {
          order: "2",
        },
        "& .slides": {
          position: "relative",
          display: "flex",
          flexDirection: "column",
          "& img.slide": {
            width: "100%",
            height: "459px",
            objectFit: "cover",
            borderRadius: "20px",
            display: "none",
            "&.active": {
              display: "block",
              animation: "$fadeInOut 5s ease-in-out ",
            },
            [theme.breakpoints.down("xl")]: {
              height: "auto",
            },
          },
          "& img.dots-img": {
            zIndex: "1",
            marginTop: "-20px",
            marginLeft: "20px",
            [theme.breakpoints.down("xl")]: {
              display: "none",
            },
          },
        },
        "& .dots": {
          display: "none",
          justifyContent: "center",
          alignItems: "center",

          gap: "4px",
          marginTop: "10px",
          "& .dot": {
            width: "6px",
            height: "6px",
            borderRadius: "50%",
            backgroundColor: "#B5BFCE",
            cursor: "pointer",
            "&.active": {
              backgroundColor: colors.doyarDark,
              width: "23px",
              borderRadius: "20px",
            },
          },
          [theme.breakpoints.down("xl")]: {
            display: "flex",
          },
        },
      },
      "& .contents": {
        padding: "41px",
        borderRadius: "20px",
        marginLeft: "-50%",
        zIndex: "1",
        maxHeight: "300px",
        backgroundColor: "#fff",
        marginTop: "78px",
        boxShadow: "0px 4px 6px rgba(90, 31, 63, 0.06)",
        [theme.breakpoints.down("xl")]: {
          marginLeft: "0px",
          marginTop: "0px",
          maxHeight: "none",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },

        "& h6": {
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "36px",
          fontWeight: "700",
          lineHeight: "50px",
          color: "#222222",
          marginBottom: "19px",
          [theme.breakpoints.down("xl")]: {
            fontSize: "30px",
            lineHeight: "37px",
            textAlign: "center",
          },
        },
        "& p": {
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "22px",
          color: colors.doyarAsh,
          marginBottom: "19px",
          [theme.breakpoints.down("xl")]: {
            textAlign: "center",
          },
        },
        "& .btns": {
          display: "flex",
          alignItems: "center",
          gap: "19px",
          paddingTop: "36px",
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            gap: "10px",
            paddingTop: "10px",
          },
        },
      },
    },
    "& .bottom": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "-50px",
      "& h6": {
        display: "none",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "70px",
        fontWeight: "700",
        lineHeight: "85px",
        color: "#fff",
        marginBottom: "19px",
        "&.text-0": {
          textShadow:
            "1px 1px 0 #000, -1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000",
        },
        "&.text-1": {
          marginTop: "-10px",
          textShadow: " 0px 1px 2px red",
        },
        "&.active": {
          display: "block",
          animation: "$fadeInOut2 5s ease-in-out ",
        },
        [theme.breakpoints.down("md")]: {
          fontSize: "50px",
          lineHeight: "60px",
          textAlign: "center",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "30px",
          lineHeight: "40px",
        },
      },
    },
  },
  "@keyframes slideRightToLeft": {
    "0%": {
      opacity: 0.8,
      transform: "translateX(60px)", // Slide from right
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)", // Slide to the left
    },
  },
  "@keyframes fadeInOut": {
    "0%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0.7,
    },
  },
  "@keyframes fadeInOut2": {
    "0%": {
      opacity: 1,
    },
    "100%": {
      opacity: 1,
    },
  },
}));
