import React, { useState, useEffect, useRef } from "react";
import { useStyles } from "./navbar.style";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Menu, Close } from "@mui/icons-material";
import logo from "../../assets/images/logo.webp";
import { Link, NavLink } from "react-router-dom";

import {
  SubIcon2,
  SubIcon3,
  SubIcon4,
  SubIcon5,
  SubIcon6,
  SubIcon8,
  SubIcon9,
  FacebookIcon2,
  InstagramIcon2,
  LinkedInIcon2,
  TwitterIcon2,
  ArrowTR,
  SubIcon7,
} from "../../assets/icons";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(null);
  const [closeNav, setCloseNav] = useState(false);
  const menuRef = useRef(null);

  const personalMenuRef = useRef(null);
  const companyMenuRef = useRef(null);
  const helpMenuRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        personalMenuRef.current &&
        !personalMenuRef.current.contains(event.target) &&
        companyMenuRef.current &&
        !companyMenuRef.current.contains(event.target) &&
        helpMenuRef.current &&
        !helpMenuRef.current.contains(event.target)
      ) {
        setOpenMenu(null);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleMenu = (menu) => {
    if (openMenu === menu) {
      setOpenMenu(null);
    } else {
      setOpenMenu(menu);
    }
  };
  const handleNav = () => {
    setCloseNav(!closeNav);
    // Toggle body class for scrolling
    if (!closeNav) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }
  };

  const classes = useStyles();
  return (
    <>
      <div className={classes.nav + " wrapper"}>
        <div className={classes.container}>
          <Link to="/">
            <img src={logo} height={"34px"} alt="logo" />
          </Link>
          <ul className={closeNav ? "navbar open-nav" : "navbar"}>
            <li className="navbar-item">
              <NavLink className="navbar-link" to="/">
                Home
              </NavLink>
            </li>
            <li
              className="navbar-item"
              onClick={() => {
                setOpenMenu("personal");
                handleMenu("personal");
              }}
              ref={personalMenuRef}
            >
              Personal
              <KeyboardArrowDownIcon />
              <ul
                className={
                  openMenu === "personal" ? "dropdown open" : "dropdown"
                }
              >
                <li className="dropdown-item">
                  <NavLink to="/send-and-receive-money">
                    <SubIcon2 />
                    <div className="down-text">
                      <h6>
                        Send/Receive Money{" "}
                        <ArrowTR
                          style={{ display: "none" }}
                          className="arrow"
                        />
                      </h6>

                      <p>Receive and send money anyday, anytime</p>
                    </div>
                  </NavLink>
                </li>
                <li className="dropdown-item">
                  <NavLink to="/savings">
                    <SubIcon3 />
                    <div className="down-text">
                      <h6>
                        Savings{" "}
                        <ArrowTR
                          style={{ display: "none" }}
                          className="arrow"
                        />
                      </h6>
                      <p>Non-interest savings plan designed for everyone</p>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li
              className="navbar-item"
              onClick={() => {
                setOpenMenu("company");
                handleMenu("company");
              }}
              ref={companyMenuRef}
            >
              Company
              <KeyboardArrowDownIcon />
              <ul
                className={
                  openMenu === "company" ? "dropdown open" : "dropdown"
                }
              >
                <li className="dropdown-item">
                  <NavLink to="/about">
                    <SubIcon4 />
                    <div className="down-text">
                      <h6>
                        About Doyar{" "}
                        <ArrowTR
                          style={{ display: "none" }}
                          className="arrow"
                        />
                      </h6>
                      <p>About us at Doyar</p>
                    </div>
                  </NavLink>
                </li>
                <li className="dropdown-item">
                  <NavLink to="/community">
                    <SubIcon5 />
                    <div className="down-text">
                      <h6>
                        Our Community{" "}
                        <ArrowTR
                          style={{ display: "none" }}
                          className="arrow"
                        />
                      </h6>
                      <p>The community we are building for everyone</p>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="navbar-item">
              <NavLink className="navbar-link" to="/careers">
                Careers
              </NavLink>
            </li>
            <li
              className="navbar-item"
              onClick={() => {
                setOpenMenu("help");
                handleMenu("help");
              }}
              ref={helpMenuRef}
            >
              Help
              <KeyboardArrowDownIcon />
              <ul
                className={openMenu === "help" ? "dropdown open" : "dropdown"}
              >
                {/* <li className="dropdown-item">
                  <Link to="/about">
                    <SubIcon9 />
                    <div className="down-text">
                      <h6>
                        FAQ{" "}
                        <ArrowTR
                          style={{ display: "none" }}
                          className="arrow"
                        />
                      </h6>
                      <p>Explore frequently asked questions</p>
                    </div>
                  </Link>
                </li> */}
                <li className="dropdown-item">
                  <NavLink to="/contact">
                    <SubIcon6 />
                    <div className="down-text">
                      <h6>
                        Get in Touch{" "}
                        <ArrowTR
                          style={{ display: "none" }}
                          className="arrow"
                        />
                      </h6>
                      <p>We are always available to assist you</p>
                    </div>
                  </NavLink>
                </li>
                <li className="dropdown-item">
                  <NavLink to="/security">
                    <SubIcon7 />
                    <div className="down-text">
                      <h6>
                        Security{" "}
                        <ArrowTR
                          style={{ display: "none" }}
                          className="arrow"
                        />
                      </h6>
                      <p>Doyar Security Protocols</p>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="download-btn navbar-item">
              <a
                className="button"
                href="https://forms.office.com/r/3z6yqBmyfK"
                target="_blank"
                rel="noreferrer"
              >
                Download App
              </a>
            </li>
            <div className="socials">
              <a
                href="https://www.linkedin.com/company/my-doyar"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon2 />
              </a>
              <a href="https://www.facebook.com/mydoyar">
                <FacebookIcon2 />
              </a>
              <a href="https://www.instagram.com/my_doyar">
                <InstagramIcon2 />
              </a>
              <a href="https://www.x.com/mydoyar">
                <TwitterIcon2 />
              </a>
            </div>
          </ul>
          {!closeNav ? (
            <Menu className="menu-icon" onClick={handleNav} />
          ) : (
            <Close className="close-icon" onClick={handleNav} />
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;
