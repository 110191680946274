import React from "react";
import { useStyles } from "./style";
import heroImg1 from "../../../assets/contact/hero-img1.webp";
import heroImg2 from "../../../assets/contact/hero-img2.webp";
import ButtonS from "../../buttons/secondary";
import { colors } from "../../../styles/colors";
import {
  ContactIcon1,
  ContactIcon2,
  ContactIcon3,
} from "../../../assets/icons";
import { Link } from "react-router-dom";
import { ArrowTR } from "../../../assets/icons";

const Hero = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="banner">
        <div className="wrapper">
          <h1
            data-aos="fade-in"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            Get in Touch
          </h1>

          {/* <div className="images"> */}
          <img
            src={heroImg2}
            alt="hero-img"
            className="img2"
            height={"488px"}
            data-aos="fade-in"
            data-aos-easing="linear"
            data-aos-duration="1000"
          />
          {/* </div> */}
        </div>
      </div>
      <div class="wrapper card-group">
        <p className="intro">
          We're available 24 hours a day, 7 days a week, to help you. You can
          use any of our provided means to get in touch with us.
        </p>
        <div className="cards">
          <div className="card">
            <ContactIcon1 />
            <h6>See FAQ's</h6>

            <p>
              Find responses to your anticipated questions within our FAQ page.
            </p>

            <Link to="/">
              Read FAQ <ArrowTR />
            </Link>
          </div>
          <div className="card">
            <ContactIcon2 />
            <h6>Chat with Mairo</h6>
            <p>
              Engage in a live chat with Mairo chatbot of our support team for
              immediate assistance
            </p>

            <Link to="/">
              Start Chat <ArrowTR />
            </Link>
          </div>
          <div className="card">
            <ContactIcon3 />
            <h6>Call Us</h6>
            <p>Make a phone call to speak with a member of our support team.</p>

            <Link to="/">
              Call us Today
              <ArrowTR />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
